<template>
    <div>
        <timeline-item :data="timelineData" />
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    import TimelineItem from '@/components/TimelineItem.vue'

    export default {
        name: 'group_timeline',
        components: { TimelineItem },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('timelines', {
                timelineLoading: state => state.selected.loading,
                timelineData: state => state.selected.data
            }),
            ...mapState('groups', {
                groupDetail: state => state.selected.data,
                groupLoading: state => state.selected.loading,
                contentFilter: state => state.selected.contentFilter
            })
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            ...mapActions('timelines', ['show']),
            fetchData () {
                this.loadMoreLoading = true
                this.show(this.$route.params.slug)
                    .finally(() => {
                        this.loadMoreLoading = false
                    })
            }
        },
        created () {
            this.fetchData()
        }
    }
</script>
